<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex xs9>
            <h2>{{ heading }}</h2>
            <span class="subheading">
              {{ subheading }}
            </span>
          </v-flex>
          <v-flex
            xs3
            text-xs-right
          >
            <rep-new-dialog />
          </v-flex>
        </v-layout>
        <v-divider />
      </v-flex>
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex
            v-for="rep in reps"
            :key="rep.id"
            xs4
          >
            <rep-card :rep="rep">
              <template v-slot:action>
                <v-btn 
                  small
                  @click="editRep(rep)"
                >
                  Edit
                </v-btn>
                <v-btn 
                  small 
                  color="info"
                  @click="selectRep(rep)"
                >
                  Applications
                </v-btn>
              </template>
            </rep-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog 
        v-model="detailDialog"
        max-width="800px"
      >
        <v-card>
          <v-card-title>{{ `${viewingRep.firstName} ${viewingRep.lastName}` }}'s Student Applications</v-card-title>
          <v-divider />
          <student-list-tbl :students="applications">
            <template v-slot:action="{ application }">
              <v-btn
                small
                color="red"
                flat
                @click="viewAsPDF(application)"
              >
                PDF
              </v-btn>
            </template>
          </student-list-tbl>
        </v-card>
      </v-dialog>
      <v-dialog 
        v-model="editDialog"
        max-width="800px"
      >
        <v-card>
          <rep-form :representative="editingRep">
            <template v-slot:action="{ rep, validate }">
              <v-btn
                small
                color="blue darken-1"
                flat
                @click="editDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                small
                color="blue darken-1"
                flat
                @click="updateRep(rep, validate)"
              >
                Save
              </v-btn>
            </template>
          </rep-form>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import RepNewDialog from '@/components/representative/RepNewDialog.vue'
import RepCard from '@/components/representative/RepCard.vue'
import RepForm from '@/components/representative/RepForm.vue'
import StudentListTable from '@/components/student/StudentListTable.vue'
import EventBus from '@/event-bus'

export default {
  components: {
    'rep-new-dialog': RepNewDialog,
    'rep-card': RepCard,
    'rep-form': RepForm,
    'student-list-tbl': StudentListTable,
  },
  data() {
    return {
      agentId: null,
      reps: null,
      detailDialog: false,
      editDialog: false,
      editingRep: {},
      viewingRep: {},
      applications: [],
    }
  },
  computed: {
    heading() {
      return 'Our Representatives'
    },
    subheading() {
      return ''
    },
  },
  created() {
    this.init()
    EventBus.$on('newRepAdded', rep => this.reps.push(rep))
  },
  methods: {
    init() {
      this.agentId = this.$route.params.id
      this.$store.dispatch('agents/allReps', this.agentId )
        .then((data) => { this.reps = data })
    },
    editRep(rep) {
      this.editingRep = { ...rep }
      this.editDialog = true
    },
    selectRep(rep) {
      this.viewingRep = { ...rep }
      this.showStudentsByRepId(rep.id)
    },
    updateRep(rep, validate) {
      if (!validate()) { return }
      this.$store.dispatch('representatives/update', rep)
        .then((data) => {
          this.reps.splice(this.reps.findIndex(item => item.id === data.id), 1, data)
          this.$store.dispatch('showMessage', { state: 'success', message: `${rep.firstName} ${rep.lastName}'s info has been updated` })
          this.editingRep = {}
          this.editDialog = false
        })
    },
    async showStudentsByRepId(repId) {
      await this.$store.dispatch('representatives/allAppsById', repId)
        .then((data) => { this.applications = data.filter(item => item.status === 'approved') })
      this.detailDialog = true
    },
    viewAsPDF({ id, applicationId }) {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      this.$store.dispatch('applications/getPdf', id)
        .then((data) => {
          const file = new Blob([data], { type: 'application/pdf' })
          const url = URL.createObjectURL(file)
          //window.open(fileURL)

          a.href = url
          a.download = applicationId
          a.click()
          window.URL.revokeObjectURL(url)
        })
    },
  },
}
</script>

<style>

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("\n      New Representative\n    ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("New Representative")])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{attrs:{"label":"First Name","rules":[
                  _vm.rules.required,
                  _vm.rules.length(0, 20)
                ],"counter":20},model:{value:(_vm.rep.firstName),callback:function ($$v) {_vm.$set(_vm.rep, "firstName", $$v)},expression:"rep.firstName"}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{attrs:{"label":"Last Name","rules":[
                  _vm.rules.required,
                  _vm.rules.length(0, 20)
                ],"counter":20},model:{value:(_vm.rep.lastName),callback:function ($$v) {_vm.$set(_vm.rep, "lastName", $$v)},expression:"rep.lastName"}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{attrs:{"label":"Email","rules":[
                  _vm.rules.required,
                  _vm.rules.email
                ]},model:{value:(_vm.rep.email),callback:function ($$v) {_vm.$set(_vm.rep, "email", $$v)},expression:"rep.email"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{attrs:{"label":"Phone","rules":[
                  _vm.rules.required
                ]},model:{value:(_vm.rep.phone),callback:function ($$v) {_vm.$set(_vm.rep, "phone", $$v)},expression:"rep.phone"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","flat":""},on:{"click":_vm.close}},[_vm._v("\n        Cancel\n      ")]),_c('v-btn',{attrs:{"color":"blue darken-1","flat":""},on:{"click":_vm.save}},[_vm._v("\n        Save\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
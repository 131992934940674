<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-on="on"
      >
        New Representative
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">New Representative</span>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs4>
                <v-text-field
                  v-model="rep.firstName"
                  label="First Name"
                  :rules="[
                    rules.required,
                    rules.length(0, 20)
                  ]"
                  :counter="20"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="rep.lastName"
                  label="Last Name"
                  :rules="[
                    rules.required,
                    rules.length(0, 20)
                  ]"
                  :counter="20"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="rep.email"
                  label="Email"
                  :rules="[
                    rules.required,
                    rules.email
                  ]"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="rep.phone"
                  label="Phone"
                  :rules="[
                    rules.required
                  ]"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          flat
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          flat
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/event-bus'
import validate from '../../custom/validate'

export default {
  data() {
    return {
      dialog: false,
      agentId: null,
      rep: {},
      rules: null,
      valid: false,
    }
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    EventBus.$off('newRepAdded')
  },
  methods: {
    init() {
      this.rules = validate.rules
      this.agentId = this.$route.params.id
      this.rep.agentId = this.agentId
    },
    close() {
      this.dialog = false
    },
    save() {
      console.log(this.rep)
      if (!this.$refs.form.validate()) return
      this.$store.dispatch('representatives/create', this.rep)
        
      //this.$store.dispatch('agents/addRep', { id: this.agentId, rep: this.rep })
        .then((data) => {
          EventBus.$emit('newRepAdded', data)
          this.$refs.form.reset()
          this.dialog = false
        })
    },
  },
}
</script>

<style>

</style>
